import * as R from 'ramda'
import React, { useState, useMemo } from 'react'
import { useRouter } from 'next/router'
import { useQuery } from 'react-query'
import { Tabs, Loading, Pagination } from 'components/elements'
import { withSizes } from 'components/helpers'
import { useSession } from 'context/Session'
import { doLoadFeed } from '../modules/datasource'

import { AMQItem } from 'routes/AskMeQuestions/List/components/Feed.styles'
import { FeedPost } from 'components/collections/Post'

import * as S from './Feed.styles'

const defaultProps = {}
const propTypes = {}

const componentMap = {
  Article: S.Article,
  Question: AMQItem,
  Post: FeedPost,
}

export const Feed = ({
  isMobile,
  className,
  CustomHeader,
  CustomSidebar,
  disableAskButton,
  hideSidebar,
  customTabs,
  onLoadFeed,
  onChangeTab,
  onChangePage,
  onDeletePost,
  onPostOpen,
  defaultTab: _defaultTab,
  customItems = [],
  setCustomItems,
  customFilters = {},
}) => {
  const defaultTab = _defaultTab ? _defaultTab : 'Latest'
  const router = useRouter()
  const [crrListState, setListState] = useState([])
  const { list: crrList = [], total = 0 } = crrListState
  const { accessToken } = useSession()
  const { tab = defaultTab, page = 1 } = router.query

  const changeTab = tab => {
    setCustomItems && setCustomItems([])
    window?.scroll(0, 0)
    router.push(`?tab=${tab}`, undefined, {
      shallow: true,
    })
  }

  const changePage = page => {
    setCustomItems && setCustomItems([])
    window?.scroll(0, 0)
    router.push(`?tab=${tab}&page=${page}`, undefined, {
      shallow: true,
    })
  }

  const tabs = useMemo(() => {
    if (customTabs) {
      return customTabs
    }
    return [
      { label: 'Trending', value: 'Trending', component: componentMap.Article },
      { label: 'Latest', value: 'Latest', component: componentMap.Article },
      accessToken
        ? {
            label: 'Feed',
            value: 'feed',
            emptyMessage:
              'To see custom content in your feed, start following a topic, author or group.',
          }
        : {},
      // {
      //   label: 'Questions',
      //   value: 'questions',
      //   emptyMessage: 'No questions to show',
      //   component: componentMap.Question,
      // },
    ]
  }, [accessToken, customTabs])

  const crrTab = R.any(R.propEq('value', tab))(tabs) ? tab : 'Latest'

  const { isFetching } = useQuery(
    ['learn', { crrTab, page, customFilters }],
    () =>
      onLoadFeed
        ? onLoadFeed({ type: crrTab, page, accessToken, ...customFilters })
        : doLoadFeed({ type: crrTab, page, accessToken }),
    {
      fetchPolicy: 'no-cache',
      onSuccess: setListState,
      onError: () => setListState({}),
    }
  )

  const tabConfig = R.find(R.propEq('value', crrTab), tabs)
  const tabsContentProps = tabConfig?.tabPanelOptions || {}

  return (
    <S.Container className={className} key={`${crrTab}-feed`}>
      <S.Content>
        <S.TabsContainer>
          {CustomHeader}
          <Tabs.TabsHeader>
            <Tabs
              options={tabs}
              value={crrTab}
              onChange={onChangeTab || changeTab}
            />
          </Tabs.TabsHeader>
          <S.TabsContent {...tabsContentProps}>
            {isFetching ? (
              <Loading style={{ margin: '10px auto' }} />
            ) : !R.isEmpty(R.concat(customItems, crrList)) ? (
              <S.ListContainter>
                {R.concat(customItems, crrList)?.map((item, i) => {
                  const RenderItem =
                    tabConfig?.component ||
                    componentMap[item.type || tabConfig?.componentType]

                  const tabsOptions = tabConfig?.config || {}
                  const wrapperOptions = tabConfig?.wrapperOptions || {}

                  const itemConfig = {
                    data: item,
                    accessToken,
                    onPostOpen,
                    onDeletePost,
                    setListState,
                    setCustomItems,
                    ...tabsOptions,
                  }

                  return (
                    RenderItem && (
                      <>
                        <S.ItemWrapper
                          key={`feed-${crrTab}-${i}`}
                          disableDivider={tabConfig?.disableDivider}
                          id={`feed-${crrTab}-${i}`}
                          {...wrapperOptions}
                        >
                          <RenderItem {...itemConfig} />
                        </S.ItemWrapper>
                      </>
                    )
                  )
                })}
                <Pagination
                  style={{ marginTop: 32 }}
                  count={Math.ceil(total / 20)}
                  size={isMobile ? 'small' : 'medium'}
                  color="primary"
                  page={Number(page)}
                  onChange={(_, v) => {
                    if (onChangePage) {
                      onChangePage(v)
                    } else {
                      changePage(v)
                    }
                  }}
                />
              </S.ListContainter>
            ) : (
              <S.EmptyContainer>
                {tabConfig?.emptyMessage || 'Nothing to see here'}
              </S.EmptyContainer>
            )}
          </S.TabsContent>
        </S.TabsContainer>
        {hideSidebar ? null : CustomSidebar ? (
          CustomSidebar
        ) : (
          <S.SideBar disableAskButton={disableAskButton} />
        )}
      </S.Content>
    </S.Container>
  )
}

Feed.defaultProps = defaultProps
Feed.propTypes = propTypes

export default withSizes(Feed)
