import * as R from 'ramda'
import api from 'shared/api'

export const doLoadAMQ = async ({
  type = 'Trending',
  page,
  limit = 20,
  accessToken,
}) => {
  const {
    data: list,
    headers: { 'x-total-results': total },
  } = await api.get(
    `/public/questions/feed?type=${type}&page=${page}&limit=${limit}`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  )

  if (limit === 1) {
    const requests = [
      api.get('/public/questions/40'),
      api.get('/public/questions/39'),
    ]

    const [{ data: firstQ }, { data: secondQ }] = await Promise.all(requests)

    return {
      list: [firstQ, secondQ, ...list],
      total: 3,
    }
  }

  return { list, total }
}
export const doLoadUserFeed = async ({ page, limit = 20, accessToken }) => {
  const {
    data: list,
    headers: { 'x-total-results': total },
  } = await api.get(`/feed?page=${page}&limit=${limit}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })

  return { list, total }
}

export const doLoadFeed = async ({ type, page, accessToken }) => {
  if (type === 'feed') {
    return await doLoadUserFeed({ page, accessToken })
  }
  if (type === 'questions') {
    return await doLoadAMQ({ page, accessToken })
  }

  const {
    data: list,
    headers: { 'x-total-results': total },
  } = await api.get(
    `/public/articles/feed?type=${type}&page=${page}&limit=20`,
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  )

  // if (type === 'Trending' && Number(page) === 1) {
  //   const fixedArticle = await api.get(
  //     '/public/articles/pfizers-pregnancy-lactation-cumulative-review-reveals-damning-data-2b15c969'
  //   )

  //   return {
  //     list: R.uniqBy(R.prop('id'), [fixedArticle.data, ...list]),
  //     total,
  //   }
  // }

  return { list, total }
}

export const loadTags = async () => {
  const { data: list } = await api.get('/public/trending-topics')

  return {
    list: R.map(
      x => ({
        label: x.name,
        value: x.slug,
      }),
      list
    ),
  }
}

export const loadTopWriters = async () => {
  const {
    data: list,
    headers: { 'x-total-results': total },
  } = await api.get('/public/top-writers?page=1&limit=10')
  return {
    list,
    total,
  }
}

export const loadYourTags = async ({ username }) => {
  const { data: list } = await api.get(
    `/public/profile/${username}/following-tags?page=1&limit=5`
  )

  return {
    list: R.map(
      x => ({
        label: x.name,
        value: x.slug,
      }),
      list
    ),
  }
}

export const loadYourFollowing = async ({ username }) => {
  const { data: list } = await api.get(
    `/public/profile/${username}/following?page=1&limit=5`
  )

  return {
    list,
  }
}
