import React from 'react'
// import Link from 'next/link'
import styled from 'styled-components'

import { FcDonate } from 'react-icons/fc'

const defaultProps = {}
const propTypes = {}

// const StyledImg = styled.img`
//   width: 100%;
//   margin-bottom: 30px;
//   max-width: 245px;

//   ${({ theme }) => theme.media.phone} {
//     margin: 0 auto 30px;
//   }
// `
// const StyledLink = styled.a`
//   display: contents;
// `

const DonationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  max-width: 500px;
  padding: 20px;
  border: 1px solid ${({ theme }) => theme.colors.grey2};
  border-radius: 5px;
  margin-bottom: 30px;

  ${({ theme }) => theme.media.phone} {
    margin-bottom: 0;
  }
`

const DonationIcon = styled(FcDonate)`
  font-size: 50px;
`

const DonationTitle = styled.p`
  font-size: 20px;
  margin: 0;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
`

export const Banner = props => {
  // return (
  //   <Link
  //     href="https://www.picmiicrowdfunding.com/deal/TrialSite%20News/"
  //     passHref
  //   >
  //     <StyledLink target="_blank" rel="noreferrer">
  //       <StyledImg
  //         src="https://imagedelivery.net/WEWiZyGwyq-Q4qn_WluYYg/d662cec8-98ca-4547-529c-b537e5888b00/public"
  //         alt="Banner"
  //       />
  //     </StyledLink>
  //   </Link>
  // )
  return (
    <DonationContainer {...props}>
      <DonationIcon />
      <DonationTitle>Help us keep the news alive</DonationTitle>
      <stripe-buy-button
        buy-button-id="buy_btn_1MyFq2FtdyGfVJnrDYxDR5aJ"
        publishable-key="pk_live_qMQLyveayPg8niFCQ7nUt5nY00K4V5u7Gx"
      >
        Donate now
      </stripe-buy-button>
    </DonationContainer>
  )
}

Banner.defaultProps = defaultProps
Banner.propTypes = propTypes

export default Banner
