import * as R from 'ramda'
import React from 'react'
import styled, { css } from 'styled-components'
import Link from 'next/link'
import slugfy from 'slug'

export const Container = styled.div`
  display: flex;
  width: 100%;
  padding: 0 160px 72px;
  justify-content: center;
  ${({ theme }) => theme.media.desktop} {
    padding: 0 60px 72px;
  }
  ${({ theme }) => theme.media.tablet} {
    padding: 0 32px 56px;
    flex-direction: column;
  }
  ${({ theme }) => theme.media.phone} {
    padding: 0 16px 56px;
    flex-direction: column;
  }
`

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  max-width: 1440px;
  ${({ theme }) => theme.media.tabletMini} {
    flex-direction: column;
  }
`

export const TabsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 88px;
  ${({ theme }) => theme.media.tablet} {
    margin-right: 48px;
  }
  ${({ theme }) => theme.media.phone} {
    margin: 0;
  }
`
export const TabsContent = styled.div`
  padding: 32px 0;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`
export const ListContainter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({ theme }) => theme.media.tablet} {
    min-width: 65vw;
  }
`

export const EmptyContainer = styled.div`
  color: ${({ theme }) => theme.colors.grey};
`

const StyledImg = styled.img`
  height: 100%;
  width: 100%;
  object-position: center;
  object-fit: cover;
`

const GroupImg = styled.a`
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.primaryL};
  border-radius: 12px;
  height: 64px;
  width: 64px;
  min-height: 64px;
  min-width: 64px;
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  text-transform: uppercase;

  ${({ imgStyles }) => imgStyles}
`

const GroupContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  & + & {
    margin-top: 16px;
  }

  ${({ theme }) => theme.media.phone} {
    flex-direction: column;
    align-items: flex-start;
  }
`
const GroupHeader = styled.div`
  display: flex;
  flex-direction: column;
`
const GroupTitle = styled.a`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
`
// const GroupLastActive = styled.div`
//   font-size: 12px;
//   font-weight: 400;
//   line-height: 16px;
//   color: ${({ theme }) => theme.colors.grey};
// `
const GroupDescription = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-right: 16px;
  flex: 1;
`
const GroupMembers = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
`
const ImgWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 16px;
  ${({ previewOnly }) =>
    !previewOnly &&
    css`
      width: 30%;
      max-width: 30%;
      ${({ theme }) => theme.media.tablet} {
        width: 40%;
        max-width: 40%;
      }
      ${({ theme }) => theme.media.phone} {
        width: unset;
        max-width: unset;
        margin-bottom: 16px;
      }
    `}
`

export const Group = ({
  name,
  description,
  image,
  members,
  id,
  previewOnly,
  imgStyles,
}) => {
  const slug = name ? slugfy(name) : ''
  const link = `/groups/${id}/${slug}`
  const hasCount = !R.isNil(members)

  return (
    <GroupContainer>
      <ImgWrapper previewOnly={previewOnly}>
        <Link href={link} passHref>
          <GroupImg imgStyles={imgStyles}>
            {image ? <StyledImg src={image} /> : R.head(name)}
          </GroupImg>
        </Link>
        <GroupHeader>
          <Link href={link} passHref>
            <GroupTitle>{name}</GroupTitle>
          </Link>
          {/* <GroupLastActive>{lastActive}</GroupLastActive> */}
        </GroupHeader>
      </ImgWrapper>
      {!previewOnly && (
        <>
          <GroupDescription>{description}</GroupDescription>
          {hasCount && <GroupMembers>{members} members</GroupMembers>}
        </>
      )}
    </GroupContainer>
  )
}
