import * as R from 'ramda'
import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import Link from 'next/link'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { useRouter } from 'next/router'
import { format } from 'date-fns'
import { useQuery } from 'react-query'
import { GoComment } from 'react-icons/go'
import { BiLike } from 'react-icons/bi'
import { useSession } from 'context/Session'
import { Tag, Button, Modal, FeedBadge } from 'components/elements'
import { getCloudFlareImgVariant } from 'components/helpers/withSizes'
import AuthSteps from 'components/collections/AuthSteps'
import { getAlphaRgb } from 'components/layouts'
import { withSizes } from 'components/helpers'
import Banner from './Banner'
import {
  loadTags,
  loadTopWriters,
  loadYourTags,
  loadYourFollowing,
} from '../modules/datasource'

import { HostForm } from 'routes/AskMeQuestions/List/components/Header.styles.js'

import { Group } from 'routes/Group/List/components/List.styles'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 72px 160px;
  ${({ theme }) => theme.media.desktop} {
    padding: 72px 60px;
  }
  ${({ theme }) => theme.media.tablet} {
    padding: 56px 32px;
    flex-direction: column;
  }
  ${({ theme }) => theme.media.phone} {
    padding: 0 16px 56px;
    flex-direction: column;
  }
`

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  max-width: 1440px;
  ${({ theme }) => theme.media.tabletMini} {
    flex-direction: column;
  }
`

export const TabsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 88px;
  /* @media screen and (max-width: 1400px) { */
  margin-right: 48px;
  /* } */
  ${({ theme }) => theme.media.phone} {
    margin: 0;
  }
`

export const TabsContent = styled.div`
  padding: 32px 0;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`
export const ListContainter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({ theme }) => theme.media.tablet} {
    min-width: 65vw;
  }
`

export const ItemWrapper = styled.div`
  display: flex;
  width: 100%;

  & + & {
    margin-top: 32px;
    padding-top: 32px;
    border-top: 1px solid ${({ theme }) => getAlphaRgb(theme.colors.grey, 0.5)};
  }
  ${({ customWrapper }) => customWrapper};
`

export const EmptyContainer = styled.div`
  color: ${({ theme }) => theme.colors.grey};
`

const ArticleContainer = styled.div`
  flex: 1;
  display: flex;
  width: 100%;
  & + & {
    margin-top: 32px;
  }

  ${({ theme }) => theme.media.phone} {
    flex-direction: column;
  }
`
const ArticleBanner = styled.div`
  height: 154px;
  min-width: 272px;
  max-width: 272px;
  border-radius: 0px;
  background-color: ${({ theme }) => theme.colors.grey};
  margin-right: 16px;
  position: relative;
  cursor: pointer;

  ${({ theme }) => theme.media.phone} {
    max-width: unset;
    width: 100%;
    height: 193px;
    margin-bottom: 24px;
  }
`
const StyledImg = styled.img`
  height: 100%;
  width: 100%;
  object-position: center;
  object-fit: cover;
`

const ArticleInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const AuthorContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  width: fit-content;
  /* ${({ theme }) => theme.font.body2} */
`
const AuthorImg = styled.div`
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.primaryL};
  border-radius: 50%;
  height: 31px;
  width: 31px;
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  text-transform: uppercase;
`
const AuthorDesc = styled.div`
  display: flex;
  align-items: center;
  /* @media screen and (max-width: 1400px) { */
  flex-direction: row-reverse;
  margin-top: 5px;
  /* } */
`
const SocialDesc = styled.div`
  display: flex;
  align-items: center;
  /* @media screen and (max-width: 1400px) { */
  margin: 0 0 0 20px;
  /* } */
`

const ArticleTitle = styled.h3`
  ${({ theme }) => theme.font.h3}
  font-size: 20px;
  cursor: pointer;
  max-width: 600px;
  margin: 5px 0;
`

const ArticlePublishDate = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.grey};
  text-align: left;
  /* ${({ theme }) => theme.font.body2} */
`

const ArticleHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  /* @media screen and (max-width: 1400px) { */
  flex-direction: column;
  align-items: flex-start;
  /* } */
`

const StyledLink = styled.a`
  text-decoration: unset;
  color: ${({ theme }) => theme.colors.black};
`

const CommentIcon = styled(GoComment)`
  font-size: 20px;
  margin-top: 5px;
`
const LikeIcon = styled(BiLike)`
  font-size: 20px;
`
const CommentContainer = styled.a`
  display: flex;
  align-items: center;
  margin-right: 20px;

  &:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.primary};
  }
`
const LikeContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
`
const AuthorWrapper = styled.div`
  display: flex;
  align-items: center;
  ${({ theme }) => theme.media.phone} {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
`

const BadgeTagsContainer = styled.div`
  display: flex;
  align-items: center;
  ${({ theme }) => theme.media.phone} {
    margin-bottom: 10px;
    margin-left: -10px;
  }
`

export const Article = ({ data }) => {
  const { image, profile, title, publishedAt, slug, comments, likes } = data
  const authorPath = `/p/${profile?.username}`
  const articlePath = `/a/${slug}`

  return (
    <ArticleContainer>
      <Link href={articlePath} passHref>
        <a rel="noreferrer">
          <ArticleBanner>
            {image && (
              <StyledImg
                src={getCloudFlareImgVariant(image, {
                  default: 'articleImageSmall',
                  phone: 'mobile',
                })}
              />
            )}
          </ArticleBanner>
        </a>
      </Link>
      <ArticleInfo>
        <ArticleHeader>
          <AuthorWrapper>
            <Link href={authorPath} passHref>
              <StyledLink rel="noreferrer">
                <AuthorContainer>
                  <AuthorImg>
                    {profile?.profileImage ? (
                      <StyledImg
                        src={getCloudFlareImgVariant(profile?.profileImage, {
                          default: 'profileSmall ',
                        })}
                      />
                    ) : (
                      R.head(profile?.username || 'U')
                    )}
                  </AuthorImg>
                  <div>{profile?.username}</div>
                </AuthorContainer>
              </StyledLink>
            </Link>
            <BadgeTagsContainer>
              <FeedBadge type="article" />
              {data?.groupId && (
                <Link href={`/groups/${data?.groupId}`}>
                  <a>
                    <FeedBadge type="group" label={data?.groupName} />
                  </a>
                </Link>
              )}
            </BadgeTagsContainer>
          </AuthorWrapper>
          <AuthorDesc>
            <SocialDesc>
              <LikeContainer>
                <LikeIcon />
                &nbsp;{likes > 0 ? likes : ''}
              </LikeContainer>
              <Link href={articlePath + '#comments'} passHref>
                <CommentContainer rel="noreferrer">
                  <CommentIcon />
                  &nbsp;{comments > 0 ? comments : ''}
                </CommentContainer>
              </Link>
            </SocialDesc>
            <ArticlePublishDate>
              {format(new Date(publishedAt), 'MMMM d, yyyy')}
            </ArticlePublishDate>
          </AuthorDesc>
        </ArticleHeader>
        <Link href={articlePath} passHref>
          <StyledLink rel="noreferrer">
            <ArticleTitle>{title}</ArticleTitle>
          </StyledLink>
        </Link>
      </ArticleInfo>
    </ArticleContainer>
  )
}

const SideBarContainer = styled.div`
  width: 100%;
  max-width: 350px;
  display: flex;
  flex-direction: column;
  margin-top: 9px;
  padding-bottom: 20px;
  height: fit-content;
  flex-wrap: wrap;

  ${({ theme }) => theme.media.tabletMini} {
    max-width: unset;
  }
`
const SideBarSection = styled.div`
  flex: 1;
  & + & {
    margin-top: 36px;
  }
`
const SideBarTitle = styled.div`
  ${({ theme }) => theme.font.h3}
  margin-bottom: 24px;
`

const TagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -4px;
`
const TagItem = styled(Tag)`
  margin: 4px;
  padding: 12px;
  cursor: pointer;
`
const OthersContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledOther = styled.a`
  display: flex;
  cursor: pointer;
  width: fit-content;
  & + & {
    margin-top: 16px;
  }
`
const OtherImg = styled(AuthorImg)`
  height: 48px;
  width: 48px;
  min-height: 48px;
  min-width: 48px;
  text-transform: uppercase;
`
const OtherDesc = styled.div`
  display: flex;
  flex-direction: column;
`
const OtherSingleLine = styled.div`
  color: ${({ theme }) => theme.colors.grey};
  ${({ theme }) => theme.font.body2}
`
const Author = ({
  username,
  profileImage,
  firstName,
  lastName,
  singleLineStatement,
}) => {
  const authorPath = `/p/${username}`
  return (
    <Link href={authorPath} passHref>
      <StyledOther>
        <OtherImg>
          {profileImage ? (
            <StyledImg
              src={getCloudFlareImgVariant(profileImage, {
                default: 'profileSmall ',
              })}
            />
          ) : (
            R.head(username || '')
          )}
        </OtherImg>
        <OtherDesc>
          {`${firstName || username} ${lastName || ''}`}
          <OtherSingleLine>{singleLineStatement}</OtherSingleLine>
        </OtherDesc>
      </StyledOther>
    </Link>
  )
}

const SeeMoreBtn = styled.div`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primary};
  transition: all 0.3s;
  width: fit-content;
  padding: 5px 10px;
  border-radius: 8px;
  margin: 20px auto;
  &:hover {
    background: ${({ theme }) => theme.colors.primaryL};
  }
`

const YourTopics = () => {
  const { user } = useSession()
  const { profile } = user
  const { username } = profile || {}
  const { data: tagsData = {} } = useQuery(
    ['your-tags', { username }],
    () => username && loadYourTags({ username }),
    {
      fetchPolicy: 'no-cache',
    }
  )
  const { list = [] } = tagsData || {}

  return R.isEmpty(list) ? null : (
    <SideBarSection>
      <SideBarTitle>Your topics</SideBarTitle>
      <TagsContainer>
        {list.map(tag => (
          <Link href={`/topics/${tag?.value}`} passHref key={tag.label}>
            <a>
              <TagItem label={tag.label} />
            </a>
          </Link>
        ))}
      </TagsContainer>
    </SideBarSection>
  )
}

const YourFollowing = () => {
  const { user } = useSession()
  const { profile } = user
  const { username } = profile || {}
  const { data: tagsData = {} } = useQuery(
    ['your-following', { username }],
    () => username && loadYourFollowing({ username }),
    {
      fetchPolicy: 'no-cache',
    }
  )
  const { list = [] } = tagsData || {}

  return R.isEmpty(list) ? null : (
    <SideBarSection>
      <SideBarTitle>{"You're following"}</SideBarTitle>
      <OthersContainer>
        {list.map(profile => (
          <Author key={profile.username} {...profile} />
        ))}
      </OthersContainer>
    </SideBarSection>
  )
}

const modalMap = {
  signUp: AuthSteps,
  host: HostForm,
}

const AskQuestionButton = () => {
  const [isOpen, setOpen] = useState(false)
  const { accessToken, verifyPermission } = useSession()
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const ModalComponent = modalMap[accessToken ? 'host' : 'signUp']

  const [modalConfig, setModalConfig] = useState({})

  const isStaff = verifyPermission(['Admin', 'Staff', 'SuperAdmin'])

  if (isStaff) {
    const handleClose = () => {
      setAnchorEl(null)
    }
    const handleClick = event => {
      setAnchorEl(event.currentTarget)
    }
    return (
      <>
        <Button
          style={{ width: '100%', marginBottom: 30 }}
          onClick={handleClick}
        >
          Ask a question
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <MenuItem
            onClick={() => {
              setModalConfig({
                asTSN: false,
              })
              setOpen(true)
              handleClose()
            }}
          >
            Ask
          </MenuItem>
          <MenuItem
            onClick={() => {
              setModalConfig({
                asTSN: true,
              })
              setOpen(true)
              handleClose()
            }}
          >
            Ask as TSN
          </MenuItem>
        </Menu>
        <Modal
          isOpen={isOpen}
          close={() => setOpen(false)}
          closeOnOutside={false}
        >
          <ModalComponent {...modalConfig} />
        </Modal>
      </>
    )
  }

  return (
    <>
      <Button
        style={{ width: '100%', marginBottom: 30 }}
        onClick={() => setOpen(true)}
      >
        Ask a question
      </Button>
      <Modal
        isOpen={isOpen}
        close={() => setOpen(false)}
        closeOnOutside={false}
      >
        <ModalComponent {...modalConfig} />
      </Modal>
    </>
  )
}

const GroupSideSection = styled(SideBarSection)`
  ${({ theme }) => theme.media.phone} {
    margin-top: 20px;
    ${SideBarTitle} {
      font-size: 18px;
    }
  }
`
const GroupSeeMoreBtn = styled(SeeMoreBtn)`
  ${({ theme }) => theme.media.phone} {
    margin-bottom: 0;
  }
`

const customGroupImgStyles = css`
  ${({ theme }) => theme.media.phone} {
    height: 40px;
    width: 40px;
    min-height: 40px;
    min-width: 40px;
  }
`

export const GroupSection = () => {
  const router = useRouter()
  const { user, loadGroups } = useSession()
  const { profile } = user || {}
  const { username } = profile || {}

  const { data: groupsList = [] } = useQuery(
    ['groups', { username }],
    () => username && loadGroups(username),
    {
      fetchPolicy: 'no-cache',
    }
  )

  return (
    !R.isEmpty(groupsList || []) && (
      <>
        <GroupSideSection>
          <SideBarTitle>Your Groups</SideBarTitle>
          <OthersContainer>
            {R.take(2, groupsList || []).map((item, i) => (
              <Group
                imgStyles={customGroupImgStyles}
                key={`group-${i}`}
                {...item}
                previewOnly
              />
            ))}
          </OthersContainer>
        </GroupSideSection>
        <GroupSeeMoreBtn onClick={() => router.push('/groups')}>
          See more
        </GroupSeeMoreBtn>
      </>
    )
  )
}

const StyledSideBar = ({ disableAskButton, isMobile }) => {
  const router = useRouter()
  const { accessToken } = useSession()
  const { data: tagsData = {} } = useQuery(['tags', {}], () => loadTags(), {
    fetchPolicy: 'no-cache',
  })

  const { data: topWritersData = {} } = useQuery(
    ['others', {}],
    () => loadTopWriters(),
    {
      fetchPolicy: 'no-cache',
    }
  )

  const { list: tagsList = [] } = tagsData
  const { list: othersList = [] } = topWritersData

  return (
    <SideBarContainer>
      <Banner />
      {false && !disableAskButton && accessToken && <AskQuestionButton />}
      {!disableAskButton && accessToken && <YourTopics />}
      {!isMobile && <GroupSection />}
      {!disableAskButton && accessToken && <YourFollowing />}
      {!R.isEmpty(tagsList) && (
        <>
          <SideBarSection>
            <SideBarTitle>Trending topics</SideBarTitle>
            <TagsContainer>
              {tagsList.map(tag => (
                <Link href={`/topics/${tag?.value}`} passHref key={tag.label}>
                  <a>
                    <TagItem label={tag.label} />
                  </a>
                </Link>
              ))}
            </TagsContainer>
          </SideBarSection>

          <SeeMoreBtn onClick={() => router.push('/search?t=topics')}>
            See more
          </SeeMoreBtn>
        </>
      )}
      {!disableAskButton && !R.isEmpty(othersList) && (
        <>
          <SideBarSection>
            <SideBarTitle>Top Authors</SideBarTitle>
            <OthersContainer>
              {othersList.map(profile => (
                <Author key={profile.username} {...profile} />
              ))}
            </OthersContainer>
          </SideBarSection>
          <SeeMoreBtn onClick={() => router.push('/search?t=people')}>
            See more
          </SeeMoreBtn>
        </>
      )}
    </SideBarContainer>
  )
}

export const SideBar = withSizes(StyledSideBar)
