import * as R from 'ramda'
import React, { useState } from 'react'
import styled from 'styled-components'
import Link from 'next/link'
import slugfy from 'slug'
import { format } from 'date-fns'
import { useQuery } from 'react-query'
import { RiChat1Fill } from 'react-icons/ri'
import { getAlphaRgb } from 'components/layouts'
import { Tag, FeedBadge } from 'components/elements'
import { loadTopWriters } from '../modules/datasource'

import { Votes } from 'routes/AskMeQuestions/Detail/components/AskMeDetail.styles'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 160px 0;
  justify-content: center;
  align-items: center;
  ${({ theme }) => theme.media.desktop} {
    padding: 0 60px 0;
  }
  ${({ theme }) => theme.media.tablet} {
    padding: 0 32px 0;
    flex-direction: column;
  }
  ${({ theme }) => theme.media.phone} {
    padding: 0 16px 0;
    flex-direction: column;
  }
`

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  max-width: 1440px;
  ${({ theme }) => theme.media.tabletMini} {
    flex-direction: column;
  }
`

export const TabsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 88px;
  ${({ theme }) => theme.media.tablet} {
    margin-right: 48px;
  }
  ${({ theme }) => theme.media.phone} {
    margin: 0;
  }
`
export const TabsContent = styled.div`
  padding: 32px 0;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`
export const ListContainter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({ theme }) => theme.media.tablet} {
    min-width: 65vw;
  }
`

export const EmptyContainer = styled.div`
  color: ${({ theme }) => theme.colors.grey};
`

const StyledImg = styled.img`
  height: 100%;
  width: 100%;
  object-position: center;
  object-fit: cover;
`

const AuthorImg = styled.div`
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.primaryL};
  border-radius: 50%;
  height: 24px;
  width: 24px;
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`

const SideBarContainer = styled.div`
  width: 100%;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  margin-top: 9px;
  padding-bottom: 20px;
  height: fit-content;

  ${({ theme }) => theme.media.tabletMini} {
    flex-direction: row;
    max-width: unset;
  }
  ${({ theme }) => theme.media.phone} {
    flex-direction: column;
  }
`
const SideBarSection = styled.div`
  flex: 1;
`
const SideBarTitle = styled.div`
  ${({ theme }) => theme.font.h3}
  margin-bottom: 24px;
`

const OthersContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledOther = styled.div`
  display: flex;
  cursor: pointer;
  width: fit-content;
  & + & {
    margin-top: 16px;
  }
`
const OtherImg = styled(AuthorImg)`
  height: 48px;
  width: 48px;
  min-height: 48px;
  min-width: 48px;
  text-transform: uppercase;
`
const OtherDesc = styled.div`
  display: flex;
  flex-direction: column;
`
const OtherSingleLine = styled.div`
  color: ${({ theme }) => theme.colors.grey};
  ${({ theme }) => theme.font.body2}
`
const Author = ({ username, profileImage, singleLineStatement }) => {
  const authorPath = `/p/${username}`
  return (
    <Link href={authorPath} passHref>
      <StyledOther>
        <OtherImg>
          {profileImage ? <StyledImg src={profileImage} /> : R.head(username)}
        </OtherImg>
        <OtherDesc>
          {username}
          <OtherSingleLine>{singleLineStatement}</OtherSingleLine>
        </OtherDesc>
      </StyledOther>
    </Link>
  )
}

// const SeeMoreBtn = styled.div`
//   cursor: pointer;
//   color: ${({ theme }) => theme.colors.primary};
//   transition: all 0.3s;
//   width: fit-content;
//   padding: 5px 10px;
//   border-radius: 8px;
//   margin: 20px auto;
//   &:hover {
//     background: ${({ theme }) => theme.colors.primaryL};
//   }
// `

// const SideBarList = styled.div`
//   display: flex;
//   flex-direction: column;
//   div + div {
//     margin-top: 16px;
//   }
//   margin-bottom: 32px;
// `

export const SideBar = () => {
  // const [page, setPage] = useState(1)

  const { data: topWritersData = {} } = useQuery(
    ['others', {}],
    () => loadTopWriters(),
    {
      fetchPolicy: 'no-cache',
    }
  )

  const { list: othersList = [] } = topWritersData

  return (
    <SideBarContainer>
      {/* <SideBarSection>
        <SideBarTitle>AMA rules</SideBarTitle>
        <SideBarList>
          <div>1. Ask any question you like of the host.</div>
          <div>2. The AMQ host will answer in their own time.</div>
          <div>3. You will be notified when your Q is answered.</div>
        </SideBarList>
      </SideBarSection> */}
      {/* <SideBarSection>
        <SideBarTitle>How to create an AMA?</SideBarTitle>
        <SideBarList>
          <div>1. Merit level needs to be xxxx</div>
          <div>2. Have a bio and profile photo xxx</div>
        </SideBarList>
      </SideBarSection> */}
      <SideBarSection>
        <SideBarTitle>AMQ leaderboard</SideBarTitle>
        <OthersContainer>
          {othersList.map(profile => (
            <Author key={profile.username} {...profile} />
          ))}
        </OthersContainer>
      </SideBarSection>
      {/* <SeeMoreBtn onClick={() => setPage(p => p + 1)}>See more</SeeMoreBtn> */}
    </SideBarContainer>
  )
}

const AMQContainer = styled.div`
  display: flex;
  width: 100%;

  & + & {
    margin-top: 32px;
    padding-top: 32px;
    border-top: 1px solid ${({ theme }) => getAlphaRgb(theme.colors.grey, 0.5)};
  }

  ${({ theme }) => theme.media.phone} {
    flex-direction: column;
  }
`

const AMQWrapper = styled.div`
  display: flex;
  width: 100%;
`
const AMQContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  word-break: break-word;
`

const AMQHeader = styled.div`
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: space-between; */
  /* margin-bottom: 24px; */
`
// const AMQBadge = styled.div`
//   padding: 0 8px;
//   background-color: ${({ theme }) => theme.colors.accentY};
//   ${({ theme }) => theme.font.label}
//   margin-right: 12px;
//   ${({ completed }) =>
//     completed &&
//     css`
//       background-color: ${({ theme }) => theme.colors.grey2};
//     `}
// `
const AMQInfo = styled.div`
  display: flex;
  align-items: center;
  ${({ theme }) => theme.media.phone} {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
`
const AMQProfileImg = styled(AuthorImg)`
  height: 16px;
  width: 16px;
  min-height: 16px;
  min-width: 16px;
  text-transform: uppercase;
  font-size: 8px;
  padding-right: 1px;
`
const AMQProfileName = styled.a`
  display: flex;
  align-items: center;
  ${({ theme }) => theme.font.label}
  cursor: pointer;
`

const BadgeTagsContainer = styled.div`
  display: flex;
  align-items: center;
  ${({ theme }) => theme.media.phone} {
    margin-bottom: 10px;
    margin-left: -10px;
  }
`

const AMQPublishDate = styled.div`
  /* ${({ theme }) => theme.font.label} */
  font-size: 14px;
  color: ${({ theme }) => theme.colors.grey};
  text-transform: cap;
  margin-top: 5px;
`
const AMQTitle = styled.a`
  ${({ theme }) => theme.font.h3}
  font-size: 22px;
  margin-bottom: 16px;
`
const AMQDescription = styled.a`
  margin-bottom: 16px;
`
const AMQFooter = styled.a`
  display: flex;
  align-items: center;
`
const AnswerBubble = styled(RiChat1Fill)`
  color: ${({ theme }) => theme.colors.accentMint};
  font-size: 30px;
`
// const QuestionBubble = styled(AnswerBubble)`
//   color: ${({ theme }) => theme.colors.accentLilac};
//   transform: scaleX(-1);
// `
const CommentBubble = styled(AnswerBubble)`
  color: ${({ theme }) => theme.colors.grey};
  transform: scaleX(-1);
`
const StyledAMQItem = styled.div`
  display: flex;
  align-items: center;
  & + & {
    margin-left: 16px;
  }
`
const StyledAMQBubble = styled.div`
  position: relative;
  width: fit-content;
  height: 28px;
`
// const QuestionBubbleDesc = styled.div`
//   position: absolute;
//   top: 1px;
//   left: calc(50% - 7px);
//   ${({ theme }) => theme.font.bold}
// `
const AnswerBubbleDesc = styled.div`
  position: absolute;
  top: 1px;
  left: calc(50% - 6px);
  ${({ theme }) => theme.font.bold}
`
const CommentBubbleDesc = styled.div`
  position: absolute;
  top: -9px;
  left: calc(50% - 9px);
  ${({ theme }) => theme.font.bold}
  ${({ theme }) => theme.font.h3}
`
const AMQItemDesc = styled.div`
  color: ${({ theme }) => theme.colors.grey};
  margin-left: 4px;
`
// const AMQFooterQuestionItem = ({ questions }) => {
//   return (
//     <StyledAMQItem>
//       <StyledAMQBubble>
//         <QuestionBubble />
//         <QuestionBubbleDesc>Q</QuestionBubbleDesc>
//       </StyledAMQBubble>
//       <AMQItemDesc>
//         {questions}&nbsp;{`question${questions === 1 ? '' : 's'}`}
//       </AMQItemDesc>
//     </StyledAMQItem>
//   )
// }
const AMQFooterAnswersItem = ({ data }) => {
  const { answers } = data || {}
  return (
    <StyledAMQItem>
      <StyledAMQBubble>
        <AnswerBubble />
        <AnswerBubbleDesc>A</AnswerBubbleDesc>
      </StyledAMQBubble>
      <AMQItemDesc>
        {answers}&nbsp;{`answer${answers === 1 ? '' : 's'}`}
      </AMQItemDesc>
    </StyledAMQItem>
  )
}
const AMQFooterCommentsItem = ({ data }) => {
  const { comments } = data || {}
  return (
    <StyledAMQItem>
      <StyledAMQBubble>
        <CommentBubble />
        <CommentBubbleDesc>...</CommentBubbleDesc>
      </StyledAMQBubble>
      <AMQItemDesc>
        {comments}&nbsp;{`comment${comments === 1 ? '' : 's'}`}
      </AMQItemDesc>
    </StyledAMQItem>
  )
}

const ArticleBanner = styled.div`
  height: 154px;
  min-width: 272px;
  max-width: 272px;
  border-radius: 0px;
  background-color: ${({ theme }) => theme.colors.grey};
  margin-right: 16px;
  position: relative;
  cursor: pointer;

  ${({ theme }) => theme.media.phone} {
    max-width: unset;
    width: 100%;
    height: 193px;
    margin-bottom: 24px;
  }
`

const generateEllipsis = (text = '', maxSize) => {
  if (R.length(text) > maxSize) {
    return R.trim(R.take(maxSize, text)) + '...'
  }
  return text
}

export const AMQItem = props => {
  const { data, accessToken, imgPreview, style } = props
  const { profile, publishedAt, createdAt, text, title, id, votes, voted } =
    data || {}
  const slug = slugfy(title || '')
  const authorPath = `/p/${profile?.username}`
  const amqPath = `/questions/${id}/${slug}`
  const [crrVotes, setCrrVotes] = useState(votes || 0)
  const [myVote, setMyVote] = useState(voted)
  // const isCompleted = !!completed

  const voteConfig = {
    crrVotes,
    myVote,
    setMyVote,
    setCrrVotes,
    id,
    accessToken,
  }

  return (
    <AMQContainer style={style}>
      {imgPreview && (
        <Link href={amqPath} passHref>
          <a rel="noreferrer">
            <ArticleBanner>
              <StyledImg src={imgPreview} />
            </ArticleBanner>
          </a>
        </Link>
      )}
      <AMQWrapper>
        {!imgPreview && <Votes {...voteConfig} />}
        <AMQContent>
          <AMQHeader>
            <AMQInfo>
              {/* <AMQBadge completed={isCompleted}>
              {isCompleted ? 'COMPLETED' : 'ACTIVE'}
            </AMQBadge> */}
              <Link href={authorPath} passHref>
                <AMQProfileName>
                  <AMQProfileImg>
                    {profile?.profileImage ? (
                      <StyledImg src={profile?.profileImage} />
                    ) : (
                      R.head(profile?.username || 'U')
                    )}
                  </AMQProfileImg>
                  <div style={{ paddingBottom: 1 }}>{profile?.username}</div>
                </AMQProfileName>
              </Link>
              <BadgeTagsContainer>
                <FeedBadge type="amq" />
                {data?.groupId && (
                  <Link href={`/groups/${data?.groupId}`}>
                    <a>
                      <FeedBadge type="group" label={data?.groupName} />
                    </a>
                  </Link>
                )}
              </BadgeTagsContainer>
            </AMQInfo>
            <AMQPublishDate>
              {format(new Date(publishedAt || createdAt), 'LLLL d, yyyy')}
            </AMQPublishDate>
          </AMQHeader>
          <Link href={amqPath} passHref>
            <AMQTitle>{title}</AMQTitle>
          </Link>
          <Link href={amqPath} passHref>
            <AMQDescription>{generateEllipsis(text, 121)}</AMQDescription>
          </Link>

          <Link href={amqPath} passHref>
            <AMQFooter>
              {/* <AMQFooterQuestionItem {...props} /> */}
              <AMQFooterAnswersItem {...props} />
              <AMQFooterCommentsItem {...props} />
            </AMQFooter>
          </Link>
        </AMQContent>
      </AMQWrapper>
    </AMQContainer>
  )
}

export const TagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 800px;
  margin: -8px;
  margin-bottom: 20px;
  ${({ theme }) => theme.media.phone} {
    justify-content: center;
  }
`

export const TagSlug = styled(Tag)`
  cursor: pointer;
  margin: 8px;
`
